/* Reset */
@import 'tailwindcss/base';
@import 'reset.css';

/* Medialibrary pro */
@import "medialibrary-pro/styles.css";

/* Components */
@import 'tailwindcss/components';
@import 'buttons.css';
@import 'form.css';

/* Utilities */
@import 'tailwindcss/utilities';

h1, h2, h3, h4 {
    font-weight: bold;
}

.date-picker {
    width: 100% !important;
    height: 53px !important;
    border: 1px solid #CBD5E1 !important;
    @apply p-2 leading-normal text-base block border border-gray-300 text-gray-700 bg-white font-sans rounded text-left appearance-none hover:border-primary-green relative focus:border-primary-green focus:ring !important;
    border-radius: 2px !important;
}

.date-picker:focus {
    outline: none !important;

    @apply shadow-none outline-none ring-1 ring-primary-green ring-inset;
}

.w-fit {
    width: fit-content;
}
