input, select, textarea, button, div, a {
  &:focus, &:active {
    outline: none;
  }
}


.date-picker {
  width: 100% !important;
  height: 53px !important;
  border: 1px solid #CBD5E1 !important;
  @apply p-2 leading-normal text-base block border border-gray-300 text-gray-700 bg-white font-sans rounded text-left appearance-none hover:border-primary-green relative focus:border-primary-green focus:ring !important;
  border-radius: 2px !important;
}