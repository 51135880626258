.btn-primary-green {
  @apply px-4 py-2.5 inline-flex rounded-md border border-gray-300 hover:border-opacity-25 hover:border-primary-green hover:bg-white text-white hover:text-primary-green text-sm leading-4 font-medium whitespace-nowrap bg-primary-green text-white focus:text-primary-green focus:ring-2 focus:ring-offset-2 focus:ring-primary-green focus:bg-green-light;
}

.btn-primary-red {
  @apply px-4 py-2.5 inline-flex rounded-md border border-gray-300 hover:border-opacity-25 hover:border-primary-red text-white hover:text-primary-red text-sm leading-4 font-medium whitespace-nowrap bg-primary-red text-white focus:text-primary-red focus:ring-2 focus:ring-offset-2 focus:ring-primary-red focus:bg-red-light;
}

.btn-secondary {
  @apply w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:w-auto sm:text-sm
}

.btn-spinner,
.btn-spinner:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}

.btn-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.2em solid white;
  border-right: 0.2em solid white;
  border-bottom: 0.2em solid white;
  border-left: 0.2em solid transparent;
  transform: translateZ(0);
  animation: spinning 1s infinite linear;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
