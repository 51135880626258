.form-label {
  @apply mb-2 block text-base text-gray-700 select-none font-sans;
}

.form-radio {
  @apply focus:ring-greenTw-500 h-4 w-4 text-primary-green border-gray-300 font-sans
}

.form-checkbox {
  @apply focus:ring-greenTw-500 h-4 w-4 text-primary-green border-gray-300 rounded font-sans
}

.form-checkbox.error {
  @apply ring-redTw-500 h-4 w-4 text-primary-red border-gray-300 rounded font-sans
}

.w-form-editor-field {
  width: 322px;
}

.h-98px {
  height: 98px;
}


.date-picker {
  width: 100% !important;
  height: 53px !important;
  border: 1px solid #CBD5E1 !important;
  @apply p-2 leading-normal text-base block border border-gray-300 text-gray-700 bg-white font-sans rounded text-left appearance-none hover:border-primary-green relative focus:border-primary-green focus:ring !important;
  border-radius: 2px !important;
}


.form-input,
.form-textarea,
.form-select {
  @apply p-2 leading-normal text-base block border border-gray-300 text-gray-700 bg-white font-sans rounded text-left appearance-none hover:border-primary-green relative focus:border-primary-green focus:ring;

  &::placeholder {
    @apply text-gray-500;
  }
  &:focus {
    @apply shadow-none outline-none ring-1 ring-primary-green ring-inset;
  }
   &:disabled {
      @apply opacity-25 hover:border-gray-200;
    }

}


.form-select {
  @apply pr-6;

  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
  background-size: 0.7rem;
  background-repeat: no-repeat;
  background-position: right 0.7rem center;

  &::-ms-expand {
    @apply opacity-0;
  }
}

.form-input.error,
.form-textarea.error,
.form-select.error {
  @apply border-primary-red focus:ring focus:ring-primary-red;
}

.form-error {
  @apply text-primary-red text-xs;
}

.form-input,
.form-textarea,
.form-select {
  border-radius: 2px !important;
}



.date-picker {
  width: 100% !important;
  height: 53px !important;
  border: 1px solid #CBD5E1;
  @apply p-2 leading-normal text-base block border border-gray-300 text-gray-700 bg-white font-sans rounded text-left appearance-none hover:border-primary-green relative focus:border-primary-green focus:ring;

  border-radius: 2px;

}

.date-picker:focus {
  outline: none !important;

  @apply shadow-none outline-none ring-1 ring-primary-green ring-inset;
}